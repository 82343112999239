import React, { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  NavLink
} from "react-router-dom";

import Home from "../components/pages/Home";
import SchoolPicnic from "../components/pages/SchoolPicnic";
import CorporateEvent from "../components/pages/CorporateEvent";
import PrePostWedding from "../components/pages/PrePostWedding";
import FilmShooting from "../components/pages/FilmShooting";
import ContactUs from "../components/pages/ContactUs";
import CulturalPackage from "../components/pages/CulturalPackage";
import MaintancePackage from "../components/pages/MaintancePackage";

const NavBar = () => {
  const [expanded, setExpanded] = useState(false);
  const handleNavSelect = () => {
    setExpanded(false);
  };

  const handleLinkClick = () => {
    if (window.innerWidth <= 768) {
      setExpanded(!expanded);
    } else {
    }
  };

  return (
    <Router>
      <Navbar expanded={expanded} expand="lg" onSelect={handleNavSelect}>
        <Container>
          <div className="logo-mbl">
            <a href="/">
              <img src="./images/logo.png" />
            </a>
          </div>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(!expanded)}
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="justify-content-center">
              <div className="left">
                <NavLink
                  as={Link}
                  to={"/CulturalPackage"}                  
                  onClick={handleLinkClick}
                >
                  Package
                </NavLink>
                <NavLink
                  as={Link}
                  to={"/SchoolPicnic"}                  
                  onClick={handleLinkClick}
                >
                  School Picnic
                </NavLink>
                <NavLink
                  as={Link}
                  to={"/CorporateEvent"}                 
                  onClick={handleLinkClick}
                >
                  Corporate Event
                </NavLink>
              </div>
              <Navbar.Brand href="/">
                <img src="./images/logo.png" />
              </Navbar.Brand>
              <div className="right">
                <NavLink
                  as={Link}
                  to={"/PrePostWedding"}
                  onClick={handleLinkClick}
                >
                  Pre & Post Wedding
                </NavLink>
                <NavLink
                  as={Link}
                  to={"/FilmShooting"}
                  onClick={handleLinkClick}
                >
                  Film Shooting
                </NavLink>
                <NavLink
                  as={Link}
                  to={"/ContactUs"}
                  onClick={handleLinkClick}
                >
                  Contact us
                </NavLink>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div>
        <Routes>
          {/* <Route path="/" element={<Home />} />
          <Route path="/SchoolPicnic" element={<SchoolPicnic />} />
          <Route path="/CorporateEvent" element={<CorporateEvent />} />
          <Route path="/PrePostWedding" element={<PrePostWedding />} />
          <Route path="/FilmShooting" element={<FilmShooting />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/CulturalPackage" element={<CulturalPackage />} /> */}

          <Route path="/" element={<MaintancePackage />} />
          <Route path="/SchoolPicnic" element={<MaintancePackage />} />
          <Route path="/CorporateEvent" element={<MaintancePackage />} />
          <Route path="/PrePostWedding" element={<MaintancePackage />} />
          <Route path="/FilmShooting" element={<MaintancePackage />} />
          <Route path="/ContactUs" element={<MaintancePackage />} />
          <Route path="/CulturalPackage" element={<MaintancePackage />} />

        </Routes>
      </div>
    </Router>
  );
};

export default NavBar;
