import React from 'react';
import {Container, Row, Image} from 'react-bootstrap';

const MaintancePackage = () => {
  return (
    <div>
    <div><figure className='text-center'><Image src="../images/logo.png" fluid  /></figure></div>
    <div className='cs'><figure className='text-center'><Image src="../images/coming-soon2.png" fluid  /></figure></div>
    <Row>
      <div className="contact-wrap align-items-center mb20">
        <div className='whatsapp-blk'>
          <p>For more updates please</p>
          <div className='btn-whatsapp'><a href='https://wa.me/918056006677' target='_blank'><Image src="../images/ic-whatsapp.png" /> 8056006677</a></div>
        </div>
        <div className='whatsapp-blk'>
          <p>For detailed updates</p>
          <div className='btn-direction'><a href='https://maps.app.goo.gl/YU6XZcUxq39hZkD67' target='_blank'><Image src="../images/ic-direction.png" /> Direction</a></div>
        </div>  
      </div>
    </Row>
      <Container className="d-flex justify-content mb20">
    <p className='d-flex text-center mx-auto'>NH-4, Near Queensland Theme Park, Kevlur Road Tandalam, Sriperumbudur,<br />Chennai,<br /> Tamil Nadu 602105</p>
    </Container>
    </div>
  )
}

export default MaintancePackage;

