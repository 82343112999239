import React, { useState } from 'react';
import Home from "../components/pages/Home";
import CulturalPackage from '../components/pages/CulturalPackage';
import SchoolPicnic from '../components/pages/SchoolPicnic';
import CorporateEvent from '../components/pages/CorporateEvent';
import PrePostWedding from '../components/pages/PrePostWedding';
import FilmShooting from '../components/pages/FilmShooting';
import ContactUs from '../components/pages/ContactUs';

const NavBarMbl = () => {
    const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = () => {
    setIsOpen(!isOpen);

    // Toggle a className on the body element
    document.body.classList.toggle('open');
  };

  return (
    <div>
    <div className="plus-btn-pos" onClick={handleButtonClick}>
      <div className="plus-btn">
        <div className="r1"></div>
        <div className="r2"></div>
        <div className="last"></div>
      </div>
    </div>
    <div className="menu-container">
      <div className="menu-sliders"></div>
      <div className="menu-sliders"></div>
      <div className="menu-sliders"></div>
      <div className="menu">
        <div className='logo'><a href="/"><img src="./images/logo.png" /></a></div>
        <ul>
            <li><a href='/' className='act hover-1'>Home</a></li>
            <li><a href='/CulturalPackage' to={"/"} className='act hover-1'>Package</a></li>
            <li><a href='/SchoolPicnic' to={"/SchoolPicnic"} className='act'>School Picnic</a></li>
            <li><a href='/CorporateEvent' className='act'>Corporate Event</a></li>
            <li><a href='/PrePostWedding' className='act'>Pre & Post Wedding</a></li>
            <li><a href='/FilmShooting' className='act'>Film Shooting</a></li>
            <li><a href='/ContactUs' className='act'>Contact Us</a></li>  
        </ul>
      </div>
    </div>
    </div>
  )
}

export default NavBarMbl