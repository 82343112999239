import './css/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from './routes/NavBar';
import Footer from './components/Footer';
import NavBarMbl from './routes/NavBarMbl';

function App() {
  return (
    <div>
      <NavBar />
      <NavBarMbl />
      <Footer />
    </div>
  );
}

export default App;
