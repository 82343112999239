import React from 'react';

const HmVideo =  ({ videoId }) =>{
    return (
      <div className="video-container">
        <iframe  width="560" class="ytplayer" id="ytplayer"
  height="315"src="https://www.youtube.com/embed/3DXDQ9AQKjw?controls=0&modestbranding=1&showinfo=0&rel=0&autoplay=1&loop=1&mute=1&playlist=3DXDQ9AQKjw"
  frameborder="0" allowfullscreen></iframe>
      </div>
    );
  };

export default HmVideo;

