import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Home from "../components/pages/Home";
import SchoolPicnic from '../components/pages/SchoolPicnic';
import CorporateEvent from '../components/pages/CorporateEvent';
import PrePostWedding from '../components/pages/PrePostWedding';
import FilmShooting from '../components/pages/FilmShooting';
import ContactUs from '../components/pages/ContactUs';
import CulturalPackage from '../components/pages/CulturalPackage';

const Footer = () => {
  return (
    <footer className="py-4">
      <div className='ftr-img img1'></div>
      <div className='ftr-img img4'></div>
      <div className='ftr-img img5'></div>
      <div className='ftr-img img3a'></div>
      <div className='ftr-img img3b'></div>
      <Container>
        <Row>
          <Col lg={3} className='ftr-dt'>
          <a href='/'><figure className='ftr-logo'><Image src="./images/logo-ftr.png" /></figure></a>
          </Col>
          <Col lg={2} md={6} className='pt'>
            <h3>Quick links</h3>
            <ul className='links'>
              <li><a href='/' className='hover-1'>Home</a></li>
              <li><a href='/SchoolPicnic' to={"/SchoolPicnic"} className='hover-1'>School Picnic</a></li>
              <li><a href='/CorporateEvent' className='hover-1'>Corporate Event</a></li>
              <li><a href='/PrePostWedding' className='hover-1'>Pre & Post Wedding</a></li>
              <li><a href='/FilmShooting' className='hover-1'>Film Shooting</a></li>
              <li><a href='/ContactUs' className='hover-1'>Contact Us</a></li>  
            </ul> 
          </Col>
          <Col lg={3} md={6} className='pt'>
            <h3>Address</h3>
            <p>NH-4, Near Queensland Theme Park, Kevlur Road Tandalam, Sriperumbudur,<br />Chennai,<br /> Tamil Nadu 602105</p>
            <div className='btn-direction'><a href='https://maps.app.goo.gl/YU6XZcUxq39hZkD67' target='_blank'><Image src="../images/ic-direction.png" /> Direction</a></div>
          </Col>
          <Col lg={4} className='pt last'>
            <h3>Phone</h3>
            <div className="ftr-contact-wrap">
              <div className='btn-whatsapp'><a href='https://wa.me/8056006677' target='_blank'><Image src="../images/ic-whatsapp2.png" /> 8056006677</a></div>
              <div className='btn-toll'><a href='tel:4471654321' target='_blank'><Image src="../images/ic-toll-free.png" /> 4471 654321</a></div>
            </div>
          </Col>
        </Row>
      </Container>
       <a href='/'><figure className='ftr-logo-mbl'><Image src="./images/logo-ftr.png" fluid /></figure></a>
    </footer>
  );
};

export default Footer;